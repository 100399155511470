import React from "react";
import ErrorBoundary from "./src/components/errorboundary/ErrorBoundary";

export const onInitialClientRender = () => {
  // ensure page scrolls to top (e.g. instructor bio page) except for page with hash (#faq, #Curriculum ...etc)
  if (!window.location.hash.includes("#")) {
    window.scrollTo(0, 0);
  }
};

export const wrapRootElement = ({ element }) => {
  return <ErrorBoundary>{element}</ErrorBoundary>;
};
